import * as Yup from "yup";

export const AddProfessionValidator = Yup.object().shape({
  licence_no: Yup.string().optional(),
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  // specialist: Yup.string().required("Specialist is required"),
  designation: Yup.string().optional(),
  //   photo: Yup.string().optional(),
  email: Yup.string().email("invalid email").optional(),
  phone: Yup.string().optional(),
  educational_qualification: Yup.string().optional(),
  // working_since_month: Yup.number().required("Working since month is required"),
  working_since_year: Yup.string().required("Years of experience is required"),
  profession_type_id: Yup.number().required("Profession type is required"),
  nationality_id: Yup.number().optional(),
  specialities: Yup.array().optional(),
  services: Yup.array().optional(),
  languages: Yup.array().min(1, "Select atleast one language"),
});

export const AddEstablishmentValidator = Yup.object().shape({
  name: Yup.string().required("First name is required"),
  email: Yup.string().email("invalid email").optional(),
  contact_number: Yup.number().required("Contact number is required"),
  licence_no: Yup.string().optional(),
  establishment_type: Yup.number().required("Establishment type is required"),
  establishment_sub_type: Yup.number().optional(""),
  primary_photo: Yup.mixed().required("Primary photo is required"),
  address: Yup.string().required("Address is required"),
  zone_id: Yup.number().required("Zone is required"),
  city_id: Yup.number().required("City is required"),
  // pin_code: Yup.number().required("Pin code is required"),
  facilities: Yup.array().optional(),
});
export const EditEstablishmentValidator = Yup.object().shape({
  name: Yup.string().required("First name is required"),
  email: Yup.string().email("invalid email").optional(),
  contact_number: Yup.number().required("Contact number is required"),
  licence_no: Yup.string().optional(),
  establishment_type: Yup.number().required("Establishment type is required"),
  establishment_sub_type: Yup.number().optional(""),
  primary_photo: Yup.mixed().optional(),
  address: Yup.string().required("Address is required"),
  zone_id: Yup.number().required("Zone is required"),
  city_id: Yup.number().required("City is required"),
  // pin_code: Yup.number().required("Pin code is required"),
  facilities: Yup.array().optional(),
});
export const AddSpecialityValidator = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  icon: Yup.mixed().required("Icon is required"),
  description: Yup.string().optional(),
  tier: Yup.number().required("Tier is required"),
});

export const AddEstablishmentHoursValidator = Yup.object().shape({
  establishment_id: Yup.number().required("Establishment id is required"),
  day_of_week: Yup.number().required("Day of week cannot be blank"),
  start_time: Yup.string().required("Start time cannot be blank."),
  end_time: Yup.string().required("End time cannot be blank"),
  // is_day_off: Yup.number().optional(),
  // start_time: Yup.string().required("Start time cannot be blank."),
  // start_time: Yup.string().when("is_day_off", {
  //   is: (is_day_off) => is_day_off == 0,
  //   then: Yup.string().required("Start time cannot be blank."),
  //   otherwise: Yup.string(),
  // }),
  // end_time: Yup.string().when("is_day_off", {
  //   is: (is_day_off) => is_day_off == 0,
  //   then: Yup.string().required("Start time cannot be blank."),
  //   otherwise: Yup.string(),
  // }),
});
export const EditEstablishmentHoursValidator = Yup.object().shape({
  day_of_week: Yup.number().required("Day of week cannot be blank"),
  start_time: Yup.string().required("Start time cannot be blank."),
  end_time: Yup.string().required("End time cannot be blank"),
  // is_day_off: Yup.number().optional(),
  // start_time: Yup.string().required("Start time cannot be blank."),
  // start_time: Yup.string().when("is_day_off", {
  //   is: (is_day_off) => is_day_off == 0,
  //   then: Yup.string().required("Start time cannot be blank."),
  //   otherwise: Yup.string(),
  // }),
  // end_time: Yup.string().when("is_day_off", {
  //   is: (is_day_off) => is_day_off == 0,
  //   then: Yup.string().required("Start time cannot be blank."),
  //   otherwise: Yup.string(),
  // }),
});

export const AddDepartmentHoursValidator = Yup.object().shape({
  department_id: Yup.number().required("Department id is required"),
  day_of_week: Yup.number().required("Day of week cannot be blank"),
  // is_day_off: Yup.number().optional(),
  start_time: Yup.string().required("Start time cannot be blank."),
  end_time: Yup.string().required("End time cannot be blank"),
  // start_time: Yup.string().when("is_day_off", {
  //   is: (is_day_off) => is_day_off == 0,
  //   then: Yup.string().required("Start time cannot be blank."),
  //   otherwise: Yup.string(),
  // }),
  // end_time: Yup.string().when("is_day_off", {
  //   is: (is_day_off) => is_day_off == 0,
  //   then: Yup.string().required("Start time cannot be blank."),
  //   otherwise: Yup.string(),
  // }),
});
export const AddDepartmentHolidayValidator = Yup.object().shape({
  department_id: Yup.number().required("Department id is required"),
  date: Yup.date().required("Date cannot be blank"),
  occasion: Yup.string().optional(),
});
export const AddEstablishmentHolidayValidator = Yup.object().shape({
  establishment_id: Yup.number().required("Department id is required"),
  date: Yup.date().required("Date cannot be blank"),
  occasion: Yup.string().optional(),
});

export const EditDepartmentHoursValidator = Yup.object().shape({
  day_of_week: Yup.number().required("Day of week cannot be blank"),
  start_time: Yup.string().required("Start time cannot be blank."),
  end_time: Yup.string().required("End time cannot be blank"),
  // is_day_off: Yup.number().optional(),
  // start_time: Yup.string().required("Start time cannot be blank."),
  // start_time: Yup.string().when("is_day_off", {
  //   is: (is_day_off) => is_day_off == 0,
  //   then: Yup.string().required("Start time cannot be blank."),
  //   otherwise: Yup.string(),
  // }),
  // end_time: Yup.string().when("is_day_off", {
  //   is: (is_day_off) => is_day_off == 0,
  //   then: Yup.string().required("Start time cannot be blank."),
  //   otherwise: Yup.string(),
  // }),
});

export const AddDepartmentValidator = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  establishment_id: Yup.number().required("Establishment is required"),
  speciatlities: Yup.array().optional(),
  professions: Yup.array().optional(),
});

// export const AddDepartmentHoursValidator = Yup.object().shape({
//   department_id: Yup.number().required("Department is required"),
//   hours_data: Yup.array(
//     Yup.object({
//       day_of_week: Yup.number().required("Day of week cannot be blank"),
//       start_time: Yup.string().required("Start time cannot be blank."),
//       end_time: Yup.string().required("End time cannot be blank"),
//       // is_day_off: Yup.number().optional(),
//     })
//   ),
// });
// export const AddEstablishmentHoursValidator = Yup.object().shape({
//   establishment_id: Yup.number().required("Department is required"),
//   hours_data: Yup.array(
//     Yup.object({
//       day_of_week: Yup.number().required("Day of week cannot be blank"),
//       start_time: Yup.string().required("Start time cannot be blank."),
//       end_time: Yup.string().required("End time cannot be blank"),
//       // is_day_off: Yup.number().optional(),
//     })
//   ),
// });
// export const AddSpecialityValidator = Yup.object().shape({
//   name: Yup.string().required("Name is required"),
//   icon: Yup.mixed().required("Icon is required"),
//   description: Yup.string().optional(),
//   tier: Yup.number().required("Please select is required"),
// });
export const EditSpecialityValidator = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  // icon: Yup.mixed().required("Icon is required"),
  description: Yup.string().optional(),
  tier: Yup.number().required("Tier is required"),
});
